import React from 'react'

/**
 * Custom hook to set single properties on state objects.
 *
 * e.g.
 * const [state, setState] = useState({ name: 'John Doe' })
 * const setProp = useSetProp(setState)
 *
 * const setName = setProp('name')
 * setName('Jane Doe')
 */
export function useSetProp<T>(setter: (callback: (t: T) => T) => void) {
  return React.useCallback(
    <K extends keyof T>(prop: K) => {
      return (value: T[K]) => {
        setter((t) => ({ ...t, [prop]: value }))
      }
    },
    [setter]
  )
}
