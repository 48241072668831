import React from 'react'
import { RenderElementProps, ReactEditor } from 'slate-react'
import { Transforms, Editor, Node, Point, Range } from 'slate'

const listTypes = ['checkbox-list', 'ordered-list', 'unordered-list'] as const
export type ListType = typeof listTypes[any]
const listItemType = {
  'checkbox-list': 'checkbox-item',
  'ordered-list': 'list-item',
  'unordered-list': 'list-item',
}

export interface ElementProps extends RenderElementProps {}

const Element: React.FC<ElementProps> = ({ attributes, element, children }) => {
  switch (element.type) {
    case 'checkbox-list':
      return (
        <ul {...attributes} className="checkbox-list">
          {children}
        </ul>
      )

    case 'ordered-list':
      return <ol {...attributes}>{children}</ol>

    case 'unordered-list':
      return <ul {...attributes}>{children}</ul>

    case 'checkbox-item':
      return (
        <li
          {...attributes}
          style={{
            display: 'flex',
          }}
        >
          <span contentEditable={false}>
            <input
              tabIndex={-1}
              type="checkbox"
              style={{ marginRight: '.5em' }}
            />
          </span>
          <span contentEditable={true} suppressContentEditableWarning>
            {children}
          </span>
        </li>
      )

    case 'list-item':
      return <li {...attributes}>{children}</li>

    default:
      return <div {...attributes}>{children}</div>
  }
}

export default Element

/** Is given element of the given type */
export function isElementOf(editor: Editor, type: string): boolean {
  const [match] = Editor.nodes(editor, {
    match: matchType(type),
  })

  return !!match
}

export function renderElement(props: ElementProps) {
  return <Element {...props} />
}

export function toggleElement(editor: Editor, type: string) {
  const isTypeActive = isElementOf(editor, type)
  const isList = listTypes.includes(type as ListType)

  Transforms.unwrapNodes(editor, {
    match: matchType(...listTypes),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isTypeActive
      ? 'paragraph'
      : listItemType[type as keyof typeof listItemType] ?? type,
  })

  if (!isTypeActive && isList) {
    Transforms.wrapNodes(editor, {
      type,
      children: [],
    })
  }
}

export function withLists<TEditor extends Editor & ReactEditor>(
  editor: TEditor
): TEditor {
  const { deleteBackward, insertData } = editor

  editor.insertData = function (data): void {
    const fragment = data.getData('application/x-slate-fragment')

    if (fragment) {
      const decoded = decodeURIComponent(atob(fragment))
      const parsedNodes = JSON.parse(decoded) as Node[]

      // When pasting into an empty paragraph, unwrap
      if (Node.string(editor).length === 0) {
        Transforms.unwrapNodes(editor, {
          match: matchType('paragraph'),
        })
      }

      Transforms.insertNodes(editor, parsedNodes)

      console.log('fragment:', JSON.stringify(parsedNodes, undefined, 2))
      return
    }

    // const html = data.getData('text/html')

    // if (html) {
    //   const parsed = new DOMParser().parseFromString(html, 'text/html')
    //   console.log(parsed.body)
    // }

    insertData(data)
  }

  // editor.normalizeNode = function (...args) {
  //   normalizeNode(...args)
  // }

  editor.deleteBackward = function (...args) {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      // determine if we are in a checkbox-item
      const [match] = Editor.nodes(editor, {
        match: matchType('checkbox-item', 'list-item'),
      })

      if (match) {
        // determine if this is the only list item in the list
        const [grandparent] = Editor.parent(editor, selection, {
          depth: 2,
        })
        const isOnlyItem = grandparent.children.length === 1

        if (isOnlyItem) {
          const [, path] = match
          const start = Editor.start(editor, path)

          // are we at the start of the node
          if (Point.equals(selection.anchor, start)) {
            toggleElement(editor, 'paragraph')
            return
          }
        }
      }
    }

    return deleteBackward(...args)
  }

  return editor
}

function matchType(...types: string[]) {
  return function match(node: Node): boolean {
    return types.includes(node.type as string)
  }
}
