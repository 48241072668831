import { TimeSlot } from '../graphql/typesAndHooks'

/**
 * Generate a sequence of dates that correspond to a given date range
 */
export function* weeklyEvents(
  { start, end, recurrenceRate, recurrenceDays }: TimeSlot,
  [filterStart, filterEnd]: [Date, Date]
): Generator<Date, void, unknown> {
  let day = 0
  if (!recurrenceDays) {
    recurrenceDays = '1111111'
  }

  const startDate = new Date(start)
  let endDate: Date

  // adjust to earlier of timeSlot end or filter end
  if (end) {
    endDate = new Date(end)
    endDate = new Date(Math.min(endDate.valueOf(), filterEnd.valueOf()))
  } else {
    endDate = filterEnd
  }

  for (
    const cur = new Date(startDate.valueOf()); // make a copy since we will be mutating cur
    cur <= endDate;
    cur.setDate(cur.getDate() + 1)
  ) {
    ++day
    const week = Math.ceil(day / 7)
    const isWeekIncluded = recurrenceRate === 1 || week % recurrenceRate === 1

    if (
      cur >= filterStart &&
      isWeekIncluded &&
      recurrenceDays[cur.getDay()] === '1'
    ) {
      yield new Date(cur)
    }
  }
}
