/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { useRecoilState } from 'recoil'
import Bulma from '../../bulma'
import { dateFilterState } from '../../state'

const componentCss = css`
  .react-daterange-picker__inputGroup__input:invalid {
    background: #fff;
  }

  @media print {
    display: none;
  }
`

export interface DateRangeFilterProps {}

const DateRangeFilter: React.FC<DateRangeFilterProps> = () => {
  const [dateFilter, setDateFilter] = useRecoilState(dateFilterState)

  return (
    <React.Fragment>
      <DateRangePicker
        css={componentCss}
        calendarIcon={
          <span className={`${Bulma.icon} ${Bulma.isSmall}`}>
            <i className="mdi mdi-calendar-month"></i>
          </span>
        }
        calendarType="US"
        clearIcon={null}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        onChange={(value: [Date, Date] | null) => {
          if (value && value[0] && value[1]) {
            setDateFilter(value)
          }
        }}
        value={dateFilter}
        yearPlaceholder="YYYY"
      />
    </React.Fragment>
  )
}

export default DateRangeFilter
