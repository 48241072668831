import React from 'react'
import { onChangeChecked } from '../util'
import Bulma from '../bulma'

const styles = {
  component: { className: `c_checkbox ${Bulma.field}` },
}

export interface CheckboxProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'checked'> {
  label: string
  onChange: (checked: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, label, onChange }) => (
  <div {...styles.component}>
    <div className={Bulma.control}>
      <label className={Bulma.label}>
        <input
          className={Bulma.checkbox}
          checked={checked}
          onChange={onChangeChecked(onChange)}
          type="checkbox"
        />
        {label}
      </label>
    </div>
  </div>
)

export default Checkbox
