/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from 'react'
import {
  Editor,
  IconButton,
  TimeSlotModal,
  DateRangeFilter,
  PrintDateRangeFilter,
  StudentFilter,
} from '..'
import {
  useGetScheduleQuery,
  Recurrence,
  Event,
  TimeSlot,
  useBatchUpdateAssignmentsMutation,
} from '../../graphql/typesAndHooks'
import {
  weeklyEvents,
  displayDate,
  useStateWithDeferredSetter,
} from '../../util'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
  dateFilterState,
  dateFilterRangeState,
  newTimeSlotState,
  StudentEditState,
  studentIdFilterState,
} from '../../state'
import { AssignedStudentList, useOnSavePutEvent, useOnSavePutTimeSlot } from '.'
import Bulma from '../../bulma'
import { useOnSavePutStudent } from './useOnSavePutStudent.hook'

const componentCss = css`
  margin: 0 10px;
`
const cellCss = css`
  border: 1px solid #ccc;
  padding: 4px;
`
const headerCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const headingCss = css`
  width: 100%;
  table-layout: fixed;
`
const cellSubjectCss = css`
  cursor: pointer;
`
const cellNonEditableCss = css`
  background-color: #eee;
  cursor: not-allowed;
`
const addTimeSlotButtonCss = css`
  margin-top: 10px;

  @media print {
    display: none;
  }
`
const tableCss = css`
  table-layout: fixed;
  width: 100%;
`
const tableHeaderFirstCss = css`
  width: 100px;
`

export interface ScheduleProps {
  id: string
}

const Schedule: React.FC<ScheduleProps> = ({ id: scheduleId }) => {
  const [startFilter, endFilter] = useRecoilValue(dateFilterState)
  const [studentIdFilter, setStudentIdFilter] = useRecoilState(
    studentIdFilterState
  )
  const dateFilterRange = useRecoilValue(dateFilterRangeState)
  const newTimeSlot = useRecoilValue(newTimeSlotState)

  const [{ fetching, data }] = useGetScheduleQuery({
    variables: {
      id: scheduleId,
      start: startFilter.toISOString(),
      end: endFilter.toISOString(),
    },
  })

  const [showNewTs, setShowNewTsCallback] = useStateWithDeferredSetter(false)

  const [
    timeSlotEditIndex,
    setTimeSlotEditIndexCallback,
  ] = useStateWithDeferredSetter<number | undefined>(undefined)

  const onSavePutEvent = useOnSavePutEvent(scheduleId)
  const onSavePutTimeSlot = useOnSavePutTimeSlot(scheduleId)
  const onSavePutStudent = useOnSavePutStudent(scheduleId)
  const [, batchUpdateAssignments] = useBatchUpdateAssignmentsMutation()
  const onDeleteAssignment = React.useCallback(
    (assignmentId) => {
      batchUpdateAssignments({
        toAdd: [],
        toDelete: [assignmentId],
        shouldAdd: false,
        shouldDelete: true,
      })
    },
    [batchUpdateAssignments]
  )

  async function onAddNewStudent(student: StudentEditState) {
    onSavePutStudent(student)
  }

  if (fetching || !data) {
    return <div>Loading...</div>
  }

  const rows = [
    ...scheduleRows(
      dateFilterRange,
      data.timeSlots.filter((ts) => ts.isActive)
    ),
  ].filter((row) =>
    studentIdFilter
      ? row.timeSlot.assignments.some(
          ({ studentId }) => studentId === studentIdFilter
        )
      : true
  )

  const students = data.students.filter((s) => s.isActive)

  return (
    <div css={componentCss}>
      <header css={headerCss}>
        <h1 css={headingCss} className={`${Bulma.title} ${Bulma.is3}`}>
          {data.getSchedule.name}
        </h1>
        <DateRangeFilter />
        <PrintDateRangeFilter />
      </header>
      <StudentFilter
        studentId={studentIdFilter}
        students={data.students}
        onChange={setStudentIdFilter}
      />
      <table css={tableCss}>
        <thead>
          <tr>
            <th css={tableHeaderFirstCss}>Subject</th>
            {dateFilterRange.map((date) => (
              <th key={date.valueOf()}>{displayDate(date)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(({ timeSlot, events }, i) => (
            <tr key={timeSlot.id}>
              <td
                css={[cellCss, cellSubjectCss]}
                onClick={setTimeSlotEditIndexCallback(i)}
              >
                {timeSlot.name}
                <AssignedStudentList
                  assignments={timeSlot.assignments}
                  students={students}
                  onClickAssignment={setTimeSlotEditIndexCallback(i)}
                  onDeleteAssignment={onDeleteAssignment}
                />
                {timeSlotEditIndex === i && (
                  <TimeSlotModal
                    timeSlot={timeSlot}
                    students={students}
                    onAddStudent={onAddNewStudent}
                    onClose={setTimeSlotEditIndexCallback(undefined)}
                    onSave={onSavePutTimeSlot}
                  />
                )}
              </td>
              {events.map(({ isEditable }, i) => {
                const event = findEvent(
                  data.events,
                  timeSlot,
                  dateFilterRange[i]
                )

                return (
                  <td
                    key={dateFilterRange[i].valueOf()}
                    css={isEditable ? cellCss : [cellCss, cellNonEditableCss]}
                  >
                    {isEditable && (
                      <Editor
                        initialValue={event?.content ?? ''}
                        onSave={(content) => {
                          onSavePutEvent({
                            id: event?.id,
                            timeSlotId: timeSlot.id!,
                            content,
                            dateTime: dateFilterRange[i].toISOString(),
                          })
                        }}
                      />
                    )}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <IconButton
                css={addTimeSlotButtonCss}
                className={Bulma.isSuccess}
                icon="plus"
                onClick={setShowNewTsCallback(true)}
                text="Subject"
              />
              {showNewTs && (
                <TimeSlotModal
                  timeSlot={newTimeSlot}
                  students={students}
                  onAddStudent={onAddNewStudent}
                  onClose={setShowNewTsCallback(false)}
                  onSave={onSavePutTimeSlot}
                />
              )}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default Schedule

function* scheduleRows(dates: Date[], timeSlots: TimeSlot[]) {
  const start = dates[0]
  const end = dates[dates.length - 1]

  for (const timeSlot of timeSlots) {
    if (timeSlot.recurrenceType === Recurrence.Weekly) {
      const eventDates = [...weeklyEvents(timeSlot, [start, end])]

      yield {
        timeSlot,
        events: dates.map((date) => ({
          isEditable: eventDates.some((e) => e.valueOf() === date.valueOf()),
        })),
      }
    }
  }
}

/** Find an event for given time slot and event date. */
function findEvent(
  events: Event[],
  timeSlot: TimeSlot,
  eventDate: Date
): Event | undefined {
  const eventDateStr = eventDate.toISOString().substr(0, 10)
  return events?.find(
    (event) =>
      event.timeSlotId === timeSlot.id &&
      event.dateTime.startsWith(eventDateStr)
  )
}
