import React from 'react'
import Bulma from '../bulma'
import { className as cl } from '../util'

const styles = {
  component: { className: `c_icon-button ${Bulma.button}` },
}

export type Icon =
  | 'calendar-month'
  | 'checkbox-marked-outline'
  | 'plus'
  | 'format-bold'
  | 'format-list-bulleted'
  | 'format-list-numbered'

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: Icon
  text?: string
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  className,
  text,
  ...rest
}) => (
  <button {...rest} {...cl(styles.component.className, className)}>
    {text && <span>{text}</span>}
    <span className={`${Bulma.icon} is-small`}>
      <i className={`mdi mdi-${icon}`}></i>
    </span>
  </button>
)

export default IconButton
