import React from 'react'
import Auth from '@aws-amplify/auth'

/**
 * Cognito user info hook that refreshes if auth state changes.
 */
export function useCognitoSession() {
  const [isSignedIn, setIsSignedIn] = React.useState(false)
  const [completeNewPassword, setCompleteNewPassword] = React.useState<
    ((newPassword: string) => Promise<void>) | null
  >(null)

  React.useEffect(() => {
    Auth.currentUserInfo().then((userInfo) => {
      if (userInfo) {
        setIsSignedIn(true)
      } else {
        setIsSignedIn(false)
        if (window.location.pathname !== '/signin') {
          window.history.pushState(null, '', '/signin')
        }
      }
    })
  }, [])

  const signIn = React.useCallback(
    async (userName: string, password: string) => {
      const user = await Auth.signIn(userName, password)
      const newPasswordRequired = user.challengeName === 'NEW_PASSWORD_REQUIRED'

      setIsSignedIn(true)

      setCompleteNewPassword(
        newPasswordRequired
          ? async (newPassword: string) => {
              await Auth.completeNewPassword(user, newPassword, {})
              window.location.pathname = '/'
            }
          : null
      )

      if (!newPasswordRequired) {
        window.location.pathname = '/'
      }

      return user
    },
    []
  )

  const signOut = React.useCallback(async () => {
    await Auth.signOut()
    // hard reset url to ensure session is cleared
    window.location.pathname = '/signin'
  }, [])

  return { isSignedIn, completeNewPassword, signIn, signOut }
}
