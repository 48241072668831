import React from 'react'
import { useCognitoSession } from '../../util/useCognitoSession.hook'

export interface SignOutProps {}

const SignOut: React.FC<SignOutProps> = () => {
  const { signOut } = useCognitoSession()

  React.useEffect(() => {
    signOut()
  }, [signOut])

  return null
}

export default SignOut
