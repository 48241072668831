import { atom, selector } from 'recoil'
import Amplify from '@aws-amplify/core'
import { firstDayOfWeek, lastDayOfWeek, range, endOfDay } from './util'
import {
  TimeSlotInput,
  Recurrence,
  StudentInput,
  AssignmentInput,
} from './graphql/typesAndHooks'

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
})

export const dateFilterState = atom({
  key: 'dateFilter',
  default: [firstDayOfWeek(), endOfDay(lastDayOfWeek())] as const,
})

export const dateFilterRangeState = selector({
  key: 'dateFilterRange',
  get: ({ get }) => {
    const [filterStart, filterEnd] = get(dateFilterState)
    return [...range(filterStart, filterEnd)]
  },
})

export const studentIdFilterState = atom({
  key: 'studentIdFilter',
  default: '',
})

export type AssignmentEditState = Omit<
  AssignmentInput,
  'scheduleId' | 'timeSlotId'
>
export type StudentEditState = Omit<StudentInput, 'scheduleId'>
export type TimeSlotEditState = Omit<TimeSlotInput, 'scheduleId'> & {
  assignments: AssignmentEditState[]
  toAddStudentIds?: string[]
  toDeleteAssignmentIds?: string[]
}

export const newTimeSlotState = selector({
  key: 'newTimeSlot',
  get: ({ get }): TimeSlotEditState => ({
    isActive: true,
    name: '',
    start: get(dateFilterState)[0].toISOString(),
    end: null,
    recurrenceType: Recurrence.Weekly,
    recurrenceRate: 1,
    recurrenceDays: '0111110',
    assignments: [],
  }),
})
