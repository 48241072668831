import {
  createClient as urqlCreateClient,
  dedupExchange,
  fetchExchange,
} from 'urql'
import { devtoolsExchange } from '@urql/devtools'
import {
  cacheExchange as createCacheExchange,
  FieldInfo,
} from '@urql/exchange-graphcache'
import {
  GetScheduleDocument,
  GetScheduleQueryVariables,
  TimeSlot,
  GetScheduleQuery,
  SchedulesDocument,
  SchedulesQuery,
  Schedule,
  Student,
  Assignment,
} from './graphql/typesAndHooks'
import {
  fetchOptionsExchange,
  mergeAppSyncFetchOptions,
} from './util/fetchOptionsExchange'

function toScheduleQueryVariables({
  arguments: args,
}: FieldInfo): GetScheduleQueryVariables {
  return {
    id: args!.scheduleId,
    start: args!.start,
    end: args!.end,
  } as GetScheduleQueryVariables
}

export function createClient() {
  const cacheExchange = createCacheExchange({
    updates: {
      Mutation: {
        putSchedule(result, _args, cache, _info) {
          const schedule = result.putSchedule as Schedule

          cache.updateQuery(
            {
              query: SchedulesDocument,
            },
            (data) => {
              ;(data as SchedulesQuery)?.schedules.push(schedule)
              return data
            }
          )
        },
        putStudent(result, _args, cache, _info) {
          const student = result.putStudent as Student

          const timeSlotsFields = cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'timeSlots' &&
                field.arguments?.scheduleId! === student.scheduleId
            )

          timeSlotsFields.map(toScheduleQueryVariables).forEach((variables) => {
            cache.updateQuery(
              {
                query: GetScheduleDocument,
                variables,
              },
              (data) => {
                const students = (data as GetScheduleQuery)?.students

                students.push(student)

                return data
              }
            )
          })
        },
        batchPutAssignments(result, _args, cache, _info) {
          const assignments = result.batchPutAssignments as Assignment[]

          const timeSlotsFields = cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'timeSlots' &&
                field.arguments?.scheduleId! === assignments[0].scheduleId
            )

          timeSlotsFields.map(toScheduleQueryVariables).forEach((variables) => {
            cache.updateQuery(
              {
                query: GetScheduleDocument,
                variables,
              },
              (data) => {
                const timeSlots = (data as GetScheduleQuery)?.timeSlots

                const timeSlot = timeSlots.find(
                  (ts) => ts.id === assignments[0].timeSlotId
                )

                if (timeSlot) {
                  timeSlot.assignments.push(...assignments)
                }

                return data
              }
            )
          })
        },
        batchDeleteAssignments(result, _args, cache, _info) {
          const assignments = result.batchDeleteAssignments as { id: string }[]
          const assignmentIds = assignments.map((a) => a.id)

          const timeSlotsFields = cache
            .inspectFields('Query')
            .filter((field) => field.fieldName === 'timeSlots')

          timeSlotsFields.map(toScheduleQueryVariables).forEach((variables) => {
            cache.updateQuery(
              {
                query: GetScheduleDocument,
                variables,
              },
              (data) => {
                const timeSlots = (data as GetScheduleQuery)?.timeSlots

                timeSlots.forEach((ts) => {
                  const i = ts.assignments.findIndex(
                    (a) => assignmentIds.indexOf(a.id) > -1
                  )

                  if (i > -1) {
                    ts.assignments.splice(i, 1)
                  }
                })

                return data
              }
            )
          })
        },
        putTimeSlot(result, _args, cache, _info) {
          const timeSlot = result.putTimeSlot as TimeSlot

          const timeSlotsFields = cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'timeSlots' &&
                field.arguments?.scheduleId! === timeSlot.scheduleId &&
                (!timeSlot.end || timeSlot.end >= field.arguments?.start!) &&
                timeSlot.start <= field.arguments?.end!
            )

          timeSlotsFields.map(toScheduleQueryVariables).forEach((variables) => {
            cache.updateQuery(
              {
                query: GetScheduleDocument,
                variables,
              },
              (data) => {
                const timeSlots = (data as GetScheduleQuery)?.timeSlots

                if (!timeSlots.find((ts) => ts.id === timeSlot.id)) {
                  timeSlots.push(timeSlot)
                }

                return data
              }
            )
          })
        },
      },
    },
  })

  return urqlCreateClient({
    url: process.env.REACT_APP_API_ENDPOINT!,
    maskTypename: true,
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      // suspenseExchange
      cacheExchange,
      fetchOptionsExchange(mergeAppSyncFetchOptions),
      fetchExchange,
    ],
  })
}
