import React from 'react'

/**
 * Wrapper on React.useState that updates if the given
 * input changes.
 */
export function useDependentState<T>(
  input: T
): [T, React.Dispatch<React.SetStateAction<T>>]
export function useDependentState<T, R>(
  input: T,
  transform: (t: T) => R
): [R, React.Dispatch<React.SetStateAction<R>>]
export function useDependentState<T, R>(
  input: T,
  transform?: Function
): [R, React.Dispatch<React.SetStateAction<R>>] {
  const [state, setState] = React.useState(() =>
    transform ? transform(input) : input
  )

  React.useEffect(() => {
    setState(() => (transform ? transform(input) : input))
  }, [input, transform])

  return [state, setState]
}
