import React from 'react'
import DatePicker from 'react-date-picker'
import { Field } from '.'
import { useDependentState } from '../util'

const styles = {
  component: { className: 'c_date-field' },
}

type ValidatedValue<TValue, TRequired extends boolean> = TRequired extends true
  ? TValue
  : TValue | null

export type DateString = string & { __opaque: 'DateString' }

export interface DateFieldProps<
  TRequired extends boolean,
  TDate extends Date | DateString
> {
  label: string
  onChange: (date: ValidatedValue<TDate, TRequired>) => void
  required?: TRequired
  value: ValidatedValue<TDate, TRequired>
}

function asDate(dateOrString: Date | DateString | null): Date | null {
  return typeof dateOrString === 'string'
    ? new Date(dateOrString as DateString)
    : (dateOrString as Date | null)
}

function DateField<TRequired extends boolean, TDate extends Date | DateString>({
  label,
  onChange,
  required,
  value,
}: DateFieldProps<TRequired, TDate>) {
  // NOTE: the react-date-picker types say this is Date | Date[],
  // but clearing the date can pass a null
  function onChangeInternal(date: TDate | null) {
    if (required && date == null) {
      return
    }

    onChange(date as ValidatedValue<TDate, TRequired>)
  }

  const [date] = useDependentState(value, asDate)

  return (
    <Field className={styles.component.className} label={label}>
      <DatePicker
        calendarType="US"
        onChange={onChangeInternal as any}
        required={required}
        value={date}
      />
    </Field>
  )
}

export default DateField
