import React, { useEffect } from 'react'
import { InputField, IconButton, Modal } from '..'
import { usePutScheduleMutation } from '../../graphql/typesAndHooks'
import { useAutofocusRef } from '../../util'
import Bulma from '../../bulma'

const styles = {
  component: { className: 'c_schedule-modal' },
}

export interface ScheduleModalProps {}

const ScheduleModal: React.FC<ScheduleModalProps> = () => {
  const [showModal, setShowModal] = React.useState(false)
  const [name, setName] = React.useState('')
  const [, putSchedule] = usePutScheduleMutation()

  const autoFocusRef = useAutofocusRef([showModal])

  useEffect(() => {
    if (showModal) {
      setName('')
    }
  }, [showModal])

  function toggleModal() {
    setShowModal((show) => !show)
  }

  async function onOk() {
    await putSchedule({
      input: {
        isActive: true,
        name,
      },
    })

    toggleModal()
  }

  return (
    <div {...styles.component}>
      <IconButton
        className={Bulma.isSuccess}
        icon="plus"
        onClick={toggleModal}
        text="Schedule"
      />
      <Modal
        isActive={showModal}
        okDisabled={name.length === 0}
        onCancel={toggleModal}
        onOk={onOk}
        title="Create Schedule"
      >
        <InputField
          ref={autoFocusRef}
          label="Name"
          onChange={setName}
          value={name}
        />
      </Modal>
    </div>
  )
}

export default ScheduleModal
