import React from 'react'
import { Field, IconButton } from '..'
import { StudentEditState } from '../../state'
import { CssProps, onChangeValue } from '../../util'
import Bulma from '../../bulma'

const styles: Record<'component', CssProps> = {
  component: { className: 'c_new-student-field' },
}

export interface NewStudentFieldProps {
  onAddStudent: (student: StudentEditState) => Promise<void>
}

const NewStudentField: React.FC<NewStudentFieldProps> = ({ onAddStudent }) => {
  const [newStudentName, setNewStudentName] = React.useState('')

  async function onClickAddStudent() {
    await onAddStudent({
      isActive: true,
      name: newStudentName,
    })
    setNewStudentName('')
  }

  return (
    <Field
      addOns={
        <IconButton
          disabled={newStudentName.length === 0}
          icon="plus"
          onClick={onClickAddStudent}
        />
      }
      children={
        <input
          className={Bulma.input}
          onChange={onChangeValue(setNewStudentName)}
          placeholder="New Student"
          value={newStudentName}
        />
      }
      className={styles.component.className!}
    />
  )
}

export default NewStudentField
