import React from 'react'
import { usePutStudentMutation } from '../../graphql/typesAndHooks'
import { StudentEditState } from '../../state'

/** Callback for saving students */
export function useOnSavePutStudent(scheduleId: string) {
  const [, putStudent] = usePutStudentMutation()

  return React.useCallback(
    async (student: StudentEditState) => {
      await putStudent({
        input: {
          ...student,
          scheduleId,
        },
      })
    },
    [putStudent, scheduleId]
  )
}
