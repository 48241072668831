export function onChangeChecked(handleValue: (checked: boolean) => void) {
  return function onChange({
    currentTarget: { checked },
  }: React.ChangeEvent<HTMLInputElement>): void {
    handleValue(checked)
  }
}

export function onChangeValue(handleValue: (value: string) => void) {
  return function onChange({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
    handleValue(value)
  }
}
