/**
 * Get local today date.
 */
export function today(): Date {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

/**
 * Get the date of the first day of the current week.
 */
export function firstDayOfWeek(): Date {
  const date = today()
  while (date.getDay() !== 0) {
    date.setDate(date.getDate() - 1)
  }
  date.setDate(date.getDate() + 1)
  return new Date(date)
}

/**
 * Get the date of the last day of the current week.
 */
export function lastDayOfWeek(): Date {
  const date = today()
  while (date.getDay() !== 6) {
    date.setDate(date.getDate() + 1)
  }
  date.setDate(date.getDate() - 1)
  return new Date(date)
}

/**
 * Get date / time of the last millisecond of the given date.
 */
export function endOfDay(date: Date): Date {
  date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
  date.setMilliseconds(-1)
  return date
}

/**
 * Generate a date range (inclusive) between a given start and end date.
 */
export function* range(start: Date, end: Date): Generator<Date, void, unknown> {
  const date = new Date(start)
  while (date <= end) {
    yield new Date(date)
    date.setDate(date.getDate() + 1)
  }
}

export function displayDate(date: Date): string {
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  return `${weekdays[date.getDay()].substr(0, 3)} - ${date.getDate()}`
}

export function monthName(dateOrNumber: Date | number): string {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return months[
    typeof dateOrNumber === 'number' ? dateOrNumber : dateOrNumber.getMonth()
  ]
}

/**
 * Returns [0m, 0d, yyyy] numbers.
 */
export function dateParts(date: Date): [number, number, number] {
  return [date.getMonth() + 1, date.getDate(), date.getFullYear()]
}
