import React from 'react'
import { RenderLeafProps } from 'slate-react'
import { Editor } from 'slate'

const styles = {
  component: { className: 'c_leaf' },
}

export interface LeafProps extends RenderLeafProps {}

const Leaf: React.FC<LeafProps> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  return (
    <span {...attributes} {...styles.component}>
      {children}
    </span>
  )
}

export default Leaf

export function isMarkActive(editor: Editor, type: string): boolean {
  const marks = Editor.marks(editor)
  return !!marks?.[type]
}

export function renderLeaf(props: RenderLeafProps) {
  return <Leaf {...props} />
}

export function toggleMark(editor: Editor, type: string) {
  const isActive = isMarkActive(editor, type)

  if (isActive) {
    Editor.removeMark(editor, type)
  } else {
    Editor.addMark(editor, type, true)
  }
}
