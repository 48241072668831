/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from 'react'
import { useSchedulesQuery } from '../../graphql/typesAndHooks'
import { Link } from 'react-router-dom'
import { ScheduleModal } from '..'
import Bulma from '../../bulma'

const componentCss = css`
  .c_schedule-modal {
    margin-top: 10px;
  }
`

export interface ScheduleListProps {}

const ScheduleList: React.FC<ScheduleListProps> = () => {
  const [{ fetching, data }] = useSchedulesQuery()

  if (fetching || !data) {
    return <div>Loading...</div>
  }

  const sorted = data.schedules
    .filter((s) => s.isActive)
    .sort((s1, s2) => s1.name.localeCompare(s2.name))

  return (
    <div css={componentCss} className="c_schedule-list">
      <h1 className={`${Bulma.title} ${Bulma.is3}`}>Schedules</h1>

      <ul>
        {sorted.map(({ id, name }) => (
          <li key={id}>
            <Link to={`/schedule/${id}`}>{name}</Link>
          </li>
        ))}
      </ul>

      <ScheduleModal />
    </div>
  )
}

export default ScheduleList
