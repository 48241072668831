import React from 'react'
import { createEditor, Node } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import Bulma from '../../bulma'
import { renderElement, Toolbar } from '.'
import { withLists } from './Element'
import { renderLeaf } from './Leaf'
import { useDependentState } from '../../util'
import ErrorBoundary from '../ErrorBoundary'

const styles: Record<
  string,
  { className?: string; style?: React.CSSProperties }
> = {
  component: {
    className: 'c_editor',
    style: { position: 'relative' },
  },
  editable: {
    className: `c_editable ${Bulma.content}`,
  },
}

const defaultValue = (): Node[] => [
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
    ],
  },
]

export interface EditorProps {
  initialValue: string
  onSave: (value: string) => void
}

const Editor: React.FC<EditorProps> = ({ initialValue, onSave }) => {
  const isDirtyRef = React.useRef(false)
  const editor = React.useMemo(() => withLists(withReact(createEditor())), [])
  const [isSelected, setIsSelected] = React.useState(false)

  const seedValue = React.useMemo(
    () =>
      initialValue ? (JSON.parse(initialValue) as Node[]) : defaultValue(),
    [initialValue]
  )

  const [value, setValue] = useDependentState(seedValue)

  const divRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const tdElement = divRef.current?.parentElement

    function onClick() {
      tdElement!.querySelector<HTMLDivElement>('.c_editable')?.focus()
    }

    if (tdElement) {
      tdElement.addEventListener('click', onClick)
      return () => {
        tdElement.removeEventListener('click', onClick)
      }
    }
  }, [])

  const onChange = React.useCallback(
    (newValue: Node[]) => {
      setIsSelected(!!editor.selection)
      setValue(newValue)

      if (newValue !== value) {
        // console.log(JSON.stringify(newValue, undefined, 2))
        isDirtyRef.current = true
      }
    },
    [editor, setValue, value]
  )

  const onBlur = React.useCallback(() => {
    if (isDirtyRef.current) {
      isDirtyRef.current = false
      onSave(JSON.stringify(value))
    }
  }, [onSave, value])

  return (
    <div ref={divRef} {...styles.component}>
      <ErrorBoundary>
        <Slate editor={editor} value={value} onChange={onChange}>
          {isSelected && <Toolbar />}
          <Editable
            {...styles.editable}
            onBlur={onBlur}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </Slate>
      </ErrorBoundary>
    </div>
  )
}

export default Editor
