/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React, { ReactNode } from 'react'

const componentCss = css`
  main {
    padding: 1em;
  }
`

export interface LayoutProps {
  nav: ReactNode
  main: ReactNode
}

const Layout: React.FC<LayoutProps> = ({
  nav: navContent,
  main: mainContent,
}) => {
  return (
    <div css={componentCss}>
      <header>{navContent}</header>
      <main>{mainContent}</main>
    </div>
  )
}

export default Layout
