import React from 'react'
import { usePutEventMutation } from '../../graphql/typesAndHooks'

/** Callback for saving schedule events */
export default function useOnSavePutEvent(scheduleId: string) {
  const [, putEvent] = usePutEventMutation()

  return React.useCallback(
    ({
      id,
      timeSlotId,
      dateTime,
      content,
    }: {
      id?: string
      timeSlotId: string
      dateTime: string
      content: string
    }) => {
      putEvent({
        input: {
          id,
          scheduleId,
          timeSlotId,
          dateTime,
          content,
        },
      })
    },
    [scheduleId, putEvent]
  )
}
