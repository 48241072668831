import React, { ErrorInfo } from 'react'

export interface ErrorBoundaryProps {}

export interface ErrorBoundaryState {
  errorMessage?: string
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo)
  }

  static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
    return {
      errorMessage: 'An error occurred.',
    }
  }

  render() {
    if (this.state.errorMessage != null) {
      return <span>{this.state.errorMessage}</span>
    }
    return this.props.children
  }
}

export default ErrorBoundary
