import React from 'react'
import { Field } from '.'
import Bulma from '../bulma'
import { onChangeValue } from '../util'

export interface InputFieldProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'> {
  label: string
  onChange: (value: string) => void
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  ({ label, onChange, type, value }, ref) => (
    <Field className="c_input-field" label={label}>
      <input
        ref={ref}
        className={Bulma.input}
        onChange={onChangeValue(onChange)}
        placeholder={label}
        type={type}
        value={value}
      />
    </Field>
  )
)

export default InputField
