import React from 'react'
import Bulma from '../bulma'
import { className } from '../util'
import { useEventCallback } from '../util'

const styles = {
  component: { className: `c_modal ${Bulma.modal}` },
  background: { className: Bulma.modalBackground },
  content: { className: Bulma.modalContent },
  card: { className: Bulma.modalCard },
  cardHead: { className: Bulma.modalCardHead },
  cardTitle: { className: Bulma.modalCardTitle },
  cardBody: { className: Bulma.modalCardBody },
  cardFoot: { className: Bulma.modalCardFoot },
  cancelButton: { className: Bulma.button },
  closeButton: { className: Bulma.delete },
  saveButton: { className: `${Bulma.button} ${Bulma.isSuccess}` },
  deleteButton: {
    className: `${Bulma.button} ${Bulma.isDanger}`,
    style: { marginLeft: 'auto' },
  },
}

function noop(): void {}

export interface ModalProps {
  isActive: boolean
  okDisabled?: boolean
  onCancel: () => void
  onOk: () => void
  onDelete?: () => void
  title: string
}

const Modal: React.FC<ModalProps> = ({
  children,
  isActive,
  okDisabled,
  onCancel,
  onOk,
  onDelete,
  title,
}) => {
  const onClickCancel = useEventCallback(
    { preventDefault: true, stopPropagation: true },
    onCancel
  )
  const onClickOk = useEventCallback(
    { preventDefault: true, stopPropagation: true },
    onOk
  )
  const onClickDelete = useEventCallback(
    { preventDefault: true, stopPropagation: true },
    onDelete ?? noop
  )

  return (
    <form
      {...className(styles.component.className, isActive ? Bulma.isActive : '')}
      onSubmit={onClickOk}
    >
      <div {...styles.background}></div>
      <div {...styles.card}>
        <header {...styles.cardHead}>
          <p {...styles.cardTitle}>{title}</p>
          <button
            {...styles.closeButton}
            aria-label="close"
            onClick={onClickCancel}
            type="button"
          ></button>
        </header>
        <section {...styles.cardBody}>{children}</section>
        <footer {...styles.cardFoot}>
          <button {...styles.saveButton} disabled={okDisabled} type="submit">
            Ok
          </button>
          <button
            {...styles.cancelButton}
            onClick={onClickCancel}
            type="button"
          >
            Cancel
          </button>
          {onDelete && (
            <button
              {...styles.deleteButton}
              onClick={onClickDelete}
              type="button"
            >
              Delete
            </button>
          )}
        </footer>
      </div>
    </form>
  )
}

export default Modal
