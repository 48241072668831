/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from 'react'
import { useRecoilValue } from 'recoil'
import { dateFilterState } from '../../state'
import { dateParts, monthName } from '../../util'

const componentCss = css`
  display: none;
  white-space: nowrap;

  @media print {
    display: inline-block;
  }
`

export interface PrintDateRangeFilterProps {}

const PrintDateRangeFilter: React.FC<PrintDateRangeFilterProps> = () => {
  const [start, end] = useRecoilValue(dateFilterState).map(dateParts)

  const dateStr =
    start[0] === end[0]
      ? `${monthName(start[0] - 1)} ${start[1]} - ${end[1]}`
      : `${monthName(start[0] - 1)} ${start[1]} - ${monthName(end[0] - 1)} ${
          end[1]
        }`

  return <div css={componentCss}>{dateStr}</div>
}

export default PrintDateRangeFilter
