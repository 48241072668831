import React from 'react'
import { ReactEditor, useSlate } from 'slate-react'
import { Icon } from '../IconButton'
import { IconButton } from '..'
import Bulma from '../../bulma'
import { Editor } from 'slate'

export interface ToolbarButtonProps {
  type: string
  icon: Icon
  toggle: (editor: Editor, type: string) => void
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  type,
  icon,
  toggle,
}) => {
  const editor = useSlate()

  const onClick = React.useCallback(() => {
    toggle(editor, type)
    ReactEditor.focus(editor)
  }, [editor, toggle, type])

  return (
    <IconButton
      className={Bulma.isSmall}
      icon={icon}
      onClick={onClick}
      tabIndex={-1}
    />
  )
}

export default ToolbarButton
