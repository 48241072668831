import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider as URQLProvider } from 'urql'
import { PrimaryNav, Route, ScheduleList, Schedule, SignIn, SignOut } from '.'
import { createClient } from '../client'
import Layout from './Layout'
import { useCognitoSession } from '../util/useCognitoSession.hook'

const client = createClient()

const App: React.FC = () => {
  const { isSignedIn } = useCognitoSession()

  return (
    <URQLProvider value={client}>
      <Router>
        <Layout
          nav={<PrimaryNav isSignedIn={isSignedIn} />}
          main={
            <>
              {isSignedIn && (
                <>
                  <Route path="/" exact={true} component={ScheduleList} />
                  <Route
                    path="/schedule/:id"
                    exact={true}
                    component={Schedule}
                  />
                </>
              )}
              <Route path="/signin" exact={true} component={SignIn} />
              <Route path="/signout" exact={true} component={SignOut} />
            </>
          }
        />
      </Router>
    </URQLProvider>
  )
}

export default App
