/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from 'react'
import Bulma from '../../bulma'
import { Student } from '../../graphql/typesAndHooks'
import { onChangeValue } from '../../util'

const componentCss = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  .label {
    margin-bottom: 0;
    margin-right: 10px;
  }
`

export interface StudentFilterProps {
  className?: string
  studentId: string
  students: Student[]
  onChange: (studentId: string) => void
}

const StudentFilter: React.FC<StudentFilterProps> = ({
  studentId,
  onChange,
  students,
}) => {
  const sortedStudents = React.useMemo(
    () => students.slice().sort((s1, s2) => s1!.name.localeCompare(s2!.name)),
    [students]
  )

  return (
    <div css={componentCss} className={Bulma.field}>
      <label className={Bulma.label}>Student</label>
      <div className={Bulma.control}>
        <select
          className={Bulma.select}
          value={studentId}
          onChange={onChangeValue(onChange)}
        >
          <option value="">- All -</option>
          {sortedStudents.map((student) => (
            <option key={student.id} value={student.id}>
              {student.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default StudentFilter
