import React from 'react'
import ReactDOM from 'react-dom'
import '../../../node_modules/@mdi/font/css/materialdesignicons.min.css'
import './index.scss'
import { App } from './components'
import * as serviceWorker from './serviceWorker'
import { RecoilRoot } from 'recoil'

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
