import React from 'react'

/**
 * Special version of useCallback used for even handling that
 * stops propagation on the incoming event.
 */
export function useEventCallback<
  TEvent extends React.BaseSyntheticEvent<any>,
  TCallback extends (event: TEvent) => any
>(
  {
    stopPropagation,
    preventDefault,
  }: { stopPropagation?: boolean; preventDefault?: boolean },
  callback: TCallback
) {
  return React.useCallback(
    (event: TEvent) => {
      stopPropagation && event.stopPropagation()
      preventDefault && event.preventDefault()
      return callback(event)
    },
    [callback, stopPropagation, preventDefault]
  )
}
