export interface CssProps {
  className?: string
  style?: React.CSSProperties
}

export function className(...names: (string | null | undefined)[]) {
  return {
    className: names.filter((n) => n != null).join(' '),
  }
}
