import React from 'react'
import { ToolbarButton } from '.'
import { CssProps } from '../../util'
import { toggleElement } from './Element'
import { toggleMark } from './Leaf'

const styles: Record<string, CssProps> = {
  component: {
    className: 'c_editor-toolbar',
    style: {
      left: '-5px',
      position: 'absolute',
      top: '-35px',
      whiteSpace: 'nowrap',
    },
  },
}

export interface ToolbarProps {}

const Toolbar: React.FC<ToolbarProps> = () => {
  /**
   * Prevent default mouse down to avoid toolbar interaction blurring
   * the Editor.
   */
  const onMouseDown = React.useCallback((event: React.MouseEvent) => {
    event.preventDefault()
  }, [])

  return (
    <div {...styles.component} onMouseDown={onMouseDown}>
      <ToolbarButton type="bold" icon="format-bold" toggle={toggleMark} />
      <ToolbarButton
        type="checkbox-list"
        icon="checkbox-marked-outline"
        toggle={toggleElement}
      />
      <ToolbarButton
        type="unordered-list"
        icon="format-list-bulleted"
        toggle={toggleElement}
      />
      <ToolbarButton
        type="ordered-list"
        icon="format-list-numbered"
        toggle={toggleElement}
      />
    </div>
  )
}

export default Toolbar
