import React from 'react'
import {
  usePutTimeSlotMutation,
  useBatchUpdateAssignmentsMutation,
} from '../../graphql/typesAndHooks'
import { TimeSlotEditState } from '../../state'

/** Callback for saving time slots */
export default function useOnSavePutTimeSlot(scheduleId: string) {
  const [, putTimeSlot] = usePutTimeSlotMutation()
  const [, batchUpdateAssignments] = useBatchUpdateAssignmentsMutation()

  return React.useCallback(
    async ({
      assignments,
      toAddStudentIds = [],
      toDeleteAssignmentIds = [],
      ...timeSlot
    }: TimeSlotEditState) => {
      const { data } = await putTimeSlot({
        input: {
          ...timeSlot,
          scheduleId,
        },
      })

      if (toAddStudentIds.length || toDeleteAssignmentIds.length) {
        await batchUpdateAssignments({
          toAdd: toAddStudentIds.map((studentId) => ({
            studentId,
            scheduleId,
            timeSlotId: data!.putTimeSlot.id,
          })),
          toDelete: toDeleteAssignmentIds,
          shouldAdd: !!toAddStudentIds.length,
          shouldDelete: !!toDeleteAssignmentIds.length,
        })
      }
    },
    [putTimeSlot, batchUpdateAssignments, scheduleId]
  )
}
