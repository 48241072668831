import React from 'react'
import Bulma from '../bulma'
import { className as c } from '../util/css'

export interface FieldProps {
  addOns?: React.ReactNode
  className?: string
  label?: string
}

const Field: React.FC<FieldProps> = ({
  addOns,
  children,
  className,
  label,
}) => (
  <div
    {...c(
      'c_field',
      Bulma.field,
      className,
      addOns ? Bulma.hasAddons : undefined
    )}
  >
    {label && <label className={Bulma.label}>{label}</label>}
    <div className={Bulma.control}>{children}</div>
    {addOns && <div className={Bulma.control}>{addOns}</div>}
  </div>
)

export default Field
