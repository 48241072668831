import React from 'react'

export function useAutofocusRef(dependencies?: React.DependencyList) {
  const elementRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (elementRef.current) {
      elementRef.current.focus()
    }
  }, dependencies ?? []) // eslint-disable-line react-hooks/exhaustive-deps

  return elementRef
}
