/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from 'react'
import { Student } from '../../graphql/typesAndHooks'
import Bulma from '../../bulma'
import { colors } from '../../css'

const componentCss = css`
  flex-direction: column;
  align-items: stretch;

  .${Bulma.tag}.${Bulma.tag} {
    justify-content: space-between;
    margin-right: 0;
  }
`

const tagColors = [
  { background: colors.blue, color: colors.white },
  { background: colors.green, color: colors.white },
  { background: colors.turquoise, color: colors.white },
  { background: colors.purple, color: colors.white },
  { background: colors.orange, color: colors.white },
]

interface AssignedStudentListItem {
  assignmentId: string
  studentId: string
  name: string
}

export interface AssignedStudentListProps {
  students: Student[]
  assignments: { id: string; studentId: string }[]
  onClickAssignment: (assignmentId: string) => void
  onDeleteAssignment: (assignmentId: string) => void
}

const AssignedStudentList: React.FC<AssignedStudentListProps> = ({
  students,
  assignments,
  onClickAssignment,
  onDeleteAssignment,
}) => {
  const items = React.useMemo(
    () =>
      assignments
        .map((a) => {
          const student = students.find((s) => s.id === a.studentId)
          if (student) {
            return {
              assignmentId: a.id,
              studentId: a.studentId,
              name: student.name,
            }
          }
          return undefined
        })
        .filter((a): a is AssignedStudentListItem => !!a)
        .sort((s1, s2) => s1!.name.localeCompare(s2!.name)),
    [assignments, students]
  )

  return (
    <div className={Bulma.tags} css={componentCss}>
      {items.map((item, i) => (
        <div
          className={Bulma.tag}
          style={tagColors[i]}
          key={item.assignmentId}
          onClick={() => onClickAssignment(item.assignmentId)}
        >
          {item.name}
          <button
            className="delete is-small noprint"
            onClick={(event) => {
              event.stopPropagation()
              onDeleteAssignment(item.assignmentId)
            }}
          ></button>
        </div>
      ))}
    </div>
  )
}

export default AssignedStudentList
