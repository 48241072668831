/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from 'react'
import { Link } from 'react-router-dom'
import Bulma from '../../bulma'

const componentCss = css`
  @media print {
    display: none;
  }

  .nav-link-list {
    justify-content: space-between;
  }
`

export interface PrimaryNavProps {
  isSignedIn: boolean
}

const PrimaryNav: React.FC<PrimaryNavProps> = ({ isSignedIn }) => (
  <nav css={componentCss} className={Bulma.tabs}>
    <ul className="nav-link-list">
      <li>{isSignedIn && <Link to="/">Schedules</Link>}</li>
      <li>
        {isSignedIn ? (
          <Link to="/signout">Sign Out</Link>
        ) : (
          <Link to="/signin">Sign In</Link>
        )}
      </li>
    </ul>
  </nav>
)

export default PrimaryNav
