/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from 'react'
import { InputField } from '..'
import Bulma from '../../bulma'
import { useAutofocusRef } from '../../util'
import { useCognitoSession } from '../../util/useCognitoSession.hook'

const componentCss = css``

export interface SignInProps {
  onSignIn: (data: {
    email: string
    password: string
    newPassword: string
  }) => void
  newPasswordRequired?: boolean
}

export const SignIn: React.FC<SignInProps> = ({
  onSignIn,
  newPasswordRequired,
}) => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const autoFocusRef = useAutofocusRef()

  const onSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      onSignIn({
        email,
        password,
        newPassword,
      })
    },
    [email, password, newPassword, onSignIn]
  )

  return (
    <div css={componentCss}>
      <form onSubmit={onSubmit}>
        <InputField
          ref={autoFocusRef}
          label="Email"
          onChange={setEmail}
          value={email}
        />
        <InputField
          label="Password"
          onChange={setPassword}
          type="password"
          value={password}
        />
        {newPasswordRequired && (
          <InputField
            label="New Password"
            onChange={setNewPassword}
            type="password"
            value={newPassword}
          />
        )}
        <button className={Bulma.button} type="submit">
          Submit
        </button>
      </form>
    </div>
  )
}

export interface SignInContainerProps {}

const SignInContainer: React.FC<SignInContainerProps> = () => {
  const { signIn, completeNewPassword } = useCognitoSession()

  const onSignIn = React.useCallback(
    async ({
      email,
      password,
      newPassword,
    }: {
      email: string
      password: string
      newPassword: string
    }) => {
      if (completeNewPassword) {
        await completeNewPassword(newPassword)
      } else {
        await signIn(email, password)
      }
    },
    [completeNewPassword, signIn]
  )

  return (
    <SignIn onSignIn={onSignIn} newPasswordRequired={!!completeNewPassword} />
  )
}

export default SignInContainer
