import React from 'react'
import { InputField, Modal } from '..'
import { useSetProp } from '../../util/useSetProp.hook'
import { StudentEditState } from '../../state'

const styles = {
  component: { className: 'c_student-modal' },
}

export interface StudentModalProps {
  student: StudentEditState
  onClose: () => void
  onSave: (student: StudentEditState) => Promise<void>
}

const StudentModal: React.FC<StudentModalProps> = ({
  student,
  onClose,
  onSave,
}) => {
  const [studentEdit, setStudentEdit] = React.useState(student)
  const setProp = useSetProp(setStudentEdit)
  const { id, isActive, name } = studentEdit
  const okDisabled = !name

  const onOk = React.useCallback(async () => {
    await onSave({
      id,
      isActive,
      name,
    })
    onClose()
  }, [id, isActive, name, onClose, onSave])

  return (
    <div {...styles.component}>
      <Modal
        isActive={true}
        okDisabled={okDisabled}
        onCancel={onClose}
        onOk={onOk}
        title="Create Student"
      >
        <fieldset>
          <InputField label="Name" onChange={setProp('name')} value={name} />
        </fieldset>
      </Modal>
    </div>
  )
}

export default StudentModal
