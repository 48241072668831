import React from 'react'
import { NewStudentField } from '.'
import { Checkbox, DateField, InputField, Modal } from '..'
import Bulma from '../../bulma'
import { Recurrence, Student } from '../../graphql/typesAndHooks'
import { StudentEditState, TimeSlotEditState } from '../../state'
import { DateString } from '../DateField'
import { useSetProp } from '../../util/useSetProp.hook'
import { useAutofocusRef, CssProps } from '../../util'

const styles: Record<'component' | 'checkBoxList', CssProps> = {
  component: { className: 'c_time-slot-modal' },
  checkBoxList: { style: { flexDirection: 'column' } },
}

export interface TimeSlotModalProps {
  timeSlot: TimeSlotEditState
  students: Student[]
  onAddStudent: (student: StudentEditState) => Promise<void>
  onClose: () => void
  onSave: (timeSlot: TimeSlotEditState) => Promise<void>
}

/**
 * Modal for creating / editing TimeSlot entities.
 */
const TimeSlotModal: React.FC<TimeSlotModalProps> = ({
  timeSlot,
  students,
  onAddStudent,
  onClose,
  onSave,
}) => {
  const [timeSlotEdit, setTimeSlotEdit] = React.useState(timeSlot)
  const setProp = useSetProp(setTimeSlotEdit)

  const studentsSorted = React.useMemo(
    () => students.sort((s1, s2) => s1.name.localeCompare(s2.name)),
    [students]
  )

  const autoFocusRef = useAutofocusRef()

  function toggleAssignment(studentId: string) {
    return () => {
      setTimeSlotEdit((ts) => {
        const { toAddStudentIds = [], toDeleteAssignmentIds = [], ...rest } = ts

        const assignments = ts.assignments.filter(
          (a) => a.studentId !== studentId
        )

        // add
        if (assignments.length === ts.assignments.length) {
          assignments.push({
            studentId,
          })

          if (!toAddStudentIds.includes(studentId)) {
            toAddStudentIds.push(studentId)
          }
        }
        // delete
        else {
          const assignmentId = ts.assignments.find(
            (a) => a.studentId === studentId
          )!.id!
          if (!toDeleteAssignmentIds.includes(assignmentId)) {
            toDeleteAssignmentIds.push(assignmentId)
          }
        }

        return {
          ...rest,
          assignments,
          toAddStudentIds,
          toDeleteAssignmentIds,
        }
      })
    }
  }

  const {
    id,
    name,
    start,
    end,
    recurrenceDays = '0111110',
    assignments,
    toAddStudentIds,
    toDeleteAssignmentIds,
  } = timeSlotEdit

  const okDisabled = name === ''

  async function onOk(isActive: boolean) {
    await onSave({
      id,
      isActive,
      name,
      start,
      end,
      recurrenceType: Recurrence.Weekly,
      recurrenceRate: 1,
      recurrenceDays,
      assignments,
      toAddStudentIds,
      toDeleteAssignmentIds,
    })
    onClose()
  }

  async function onDelete() {
    await onOk(false)
  }

  return (
    <div {...styles.component}>
      <Modal
        isActive={true}
        okDisabled={okDisabled}
        onCancel={onClose}
        onOk={() => onOk(true)}
        onDelete={onDelete}
        title={`${id ? 'Edit' : 'Create'} Subject`}
      >
        <fieldset>
          <InputField
            ref={autoFocusRef}
            label="Name"
            onChange={setProp('name')}
            value={name}
          />
          <DateField
            label="Start"
            onChange={setProp('start')}
            required={true}
            value={start as DateString}
          />
          <DateField
            label="End"
            onChange={setProp('end')}
            required={false}
            value={end as DateString}
          />
          <div className={`${Bulma.field} ${Bulma.isHorizontal}`}>
            <div className="field-label">
              <label className="label">Days</label>
            </div>
            <div {...styles.checkBoxList} className="field-body">
              {['Mon', 'Tue', 'Wed', 'Thu', 'Fri'].map((day, i) => (
                <Checkbox
                  key={day}
                  checked={recurrenceDays![i + 1] === '1'}
                  label={day}
                  onChange={(checked) =>
                    setProp('recurrenceDays')(
                      recurrenceDays!.substr(0, i + 1) +
                        (checked ? '1' : '0') +
                        recurrenceDays!.substr(i + 2)
                    )
                  }
                />
              ))}
            </div>
          </div>
          <div className={`${Bulma.field} ${Bulma.isHorizontal}`}>
            <div className="field-label">
              <label className="label">Students</label>
            </div>
            <div {...styles.checkBoxList} className="field-body">
              {studentsSorted.map((student) => (
                <Checkbox
                  key={student.id}
                  checked={assignments.some((a) => a.studentId === student.id)}
                  label={student.name}
                  onChange={toggleAssignment(student.id)}
                />
              ))}

              <NewStudentField onAddStudent={onAddStudent} />
            </div>
          </div>
        </fieldset>
      </Modal>
    </div>
  )
}

export default TimeSlotModal
