import { Exchange, Operation } from 'urql'
import { fromPromise, fromValue, map, mergeMap, pipe } from 'wonka'
import Auth from '@aws-amplify/auth'

interface MergeMaybeAsyncFetchOptions {
  (fetchOptions?: RequestInit | (() => RequestInit)):
    | RequestInit
    | Promise<RequestInit>
}

/**
 * URQL exchange that allows creating fetch options asynchronously.
 */
export const fetchOptionsExchange = (
  mergeFetchOptions: MergeMaybeAsyncFetchOptions
): Exchange => ({ forward }) => (ops$) => {
  return pipe(
    ops$,
    mergeMap((operation: Operation) => {
      const result = mergeFetchOptions(operation.context.fetchOptions)

      const fromSource =
        result instanceof Promise ? fromPromise(result) : fromValue(result)

      return pipe(
        fromSource as any,
        map((fetchOptions: RequestInit | (() => RequestInit)) => ({
          ...operation,
          context: { ...operation.context, fetchOptions },
        }))
      )
    }),
    forward
  )
}

/**
 * Merge AppSync Auth into URQL fetch options.
 */
export async function mergeAppSyncFetchOptions(
  fetchOptions?: RequestInit | (() => RequestInit)
) {
  if (typeof fetchOptions === 'function') {
    fetchOptions = fetchOptions()
  }

  const session = await Auth.currentSession()
  const jwtToken = session.getAccessToken().getJwtToken()
  // console.log('JWT:', jwtToken)

  return {
    ...fetchOptions,
    headers: {
      Authorization: jwtToken,
    },
  }
}
