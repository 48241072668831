import React from 'react'
import * as ReactRouter from 'react-router-dom'

/** Map route to component props type */
interface RouteParams {
  '/': {}
  '/schedule/:id': { id: string }
  '/schedule/:id/students': { id: string }
  '/signin': {}
  '/signout': {}
}

type RoutePath = keyof RouteParams

/** Helper type to derive route props from path */
type Params<TPath extends RoutePath> = TPath extends RoutePath
  ? RouteParams[TPath]
  : never

/** Override RouteProps with generics */
interface RouteProps<TPath extends RoutePath>
  extends Omit<ReactRouter.RouteProps, 'component' | 'path'> {
  component: React.ComponentType<Params<TPath>>
  path: TPath
}

/**
 * Route wrapper component that extracts route params
 * and passes them to the given component prop.
 */
function Route<TPath extends RoutePath>({
  component: Component,
  ...rest
}: RouteProps<TPath>) {
  return (
    <ReactRouter.Route
      {...rest}
      render={({ match: { params } }) => <Component {...params} />}
    />
  )
}

export default Route
