const classNames = {
  content: 'content',
  panel: 'panel',
  panelBlock: 'panel-block',
  tabs: 'tabs',
  title: 'title',
  subtitle: 'subtitle',
  is1: 'is-1',
  is2: 'is-2',
  is3: 'is-3',
  is4: 'is-4',
  is5: 'is-5',
  is6: 'is-6',
  isActive: 'is-active',
  isInfo: 'is-info',
  isSuccess: 'is-success',
  isWarning: 'is-warning',
  isDanger: 'is-danger',
  isHorizontal: 'is-horizontal',
  isSmall: 'is-small',
  hasAddons: 'has-addons',

  /** Component */
  button: 'button',
  delete: 'delete',
  icon: 'icon',
  tags: 'tags',
  tag: 'tag',

  /** Forms */
  input: 'input',
  checkbox: 'checkbox',
  field: 'field',
  label: 'label',
  control: 'control',
  select: 'select',

  /** Modal */
  modal: 'modal',
  modalBackground: 'modal-background',
  modalContent: 'modal-content',
  modalClose: 'modal-close',
  modalCard: 'modal-card',
  modalCardHead: 'modal-card-head',
  modalCardTitle: 'modal-card-title',
  modalCardBody: 'modal-card-body',
  modalCardFoot: 'modal-card-foot',
} as const

export default classNames
