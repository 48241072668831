import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: string;
};


export enum Recurrence {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly'
}

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  name: Scalars['String'];
};

export type ScheduleInput = {
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
};

export type Student = {
  __typename?: 'Student';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  scheduleId: Scalars['ID'];
  name: Scalars['String'];
};

export type StudentInput = {
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  scheduleId: Scalars['ID'];
  name: Scalars['String'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  scheduleId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  start: Scalars['AWSDateTime'];
  end?: Maybe<Scalars['AWSDateTime']>;
  duration?: Maybe<Scalars['Int']>;
  recurrenceType: Recurrence;
  recurrenceRate: Scalars['Int'];
  recurrenceDays?: Maybe<Scalars['String']>;
  assignments: Array<Assignment>;
};

export type TimeSlotInput = {
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  scheduleId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  start: Scalars['AWSDateTime'];
  end?: Maybe<Scalars['AWSDateTime']>;
  duration?: Maybe<Scalars['Int']>;
  recurrenceType: Recurrence;
  recurrenceRate: Scalars['Int'];
  recurrenceDays?: Maybe<Scalars['String']>;
};

export type Assignment = {
  __typename?: 'Assignment';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  scheduleId: Scalars['ID'];
  timeSlotId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type AssignmentInput = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  scheduleId: Scalars['ID'];
  timeSlotId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  scheduleId: Scalars['ID'];
  timeSlotId: Scalars['ID'];
  dateTime: Scalars['AWSDateTime'];
  content: Scalars['String'];
};

export type EventInput = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  scheduleId: Scalars['ID'];
  timeSlotId: Scalars['ID'];
  dateTime: Scalars['AWSDateTime'];
  content: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  putSchedule: Schedule;
  putStudent: Student;
  putTimeSlot: TimeSlot;
  putEvent: Event;
  batchPutAssignments: Array<Assignment>;
  batchDeleteAssignments: Array<Assignment>;
};


export type MutationPutScheduleArgs = {
  input: ScheduleInput;
};


export type MutationPutStudentArgs = {
  input: StudentInput;
};


export type MutationPutTimeSlotArgs = {
  input: TimeSlotInput;
};


export type MutationPutEventArgs = {
  input: EventInput;
};


export type MutationBatchPutAssignmentsArgs = {
  input: Array<AssignmentInput>;
};


export type MutationBatchDeleteAssignmentsArgs = {
  input: Array<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  getSchedule: Schedule;
  schedules: Array<Schedule>;
  students: Array<Student>;
  timeSlots: Array<TimeSlot>;
  assignments: Array<Assignment>;
  events: Array<Event>;
};


export type QueryGetScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryStudentsArgs = {
  scheduleId: Scalars['ID'];
};


export type QueryTimeSlotsArgs = {
  scheduleId: Scalars['ID'];
  start: Scalars['AWSDateTime'];
  end: Scalars['AWSDateTime'];
};


export type QueryAssignmentsArgs = {
  scheduleId: Scalars['ID'];
  timeSlotId: Scalars['ID'];
};


export type QueryEventsArgs = {
  scheduleId: Scalars['ID'];
  start: Scalars['AWSDateTime'];
  end: Scalars['AWSDateTime'];
};

export type GetScheduleQueryVariables = Exact<{
  id: Scalars['ID'];
  start: Scalars['AWSDateTime'];
  end: Scalars['AWSDateTime'];
}>;


export type GetScheduleQuery = (
  { __typename?: 'Query' }
  & { getSchedule: (
    { __typename?: 'Schedule' }
    & ScheduleFieldsFragment
  ), timeSlots: Array<(
    { __typename?: 'TimeSlot' }
    & TimeSlotFieldsFragment
  )>, events: Array<(
    { __typename?: 'Event' }
    & EventFieldsFragment
  )>, students: Array<(
    { __typename?: 'Student' }
    & StudentFieldsFragment
  )> }
);

export type PutEventMutationVariables = Exact<{
  input: EventInput;
}>;


export type PutEventMutation = (
  { __typename?: 'Mutation' }
  & { putEvent: (
    { __typename?: 'Event' }
    & EventFieldsFragment
  ) }
);

export type PutScheduleMutationVariables = Exact<{
  input: ScheduleInput;
}>;


export type PutScheduleMutation = (
  { __typename?: 'Mutation' }
  & { putSchedule: (
    { __typename?: 'Schedule' }
    & ScheduleFieldsFragment
  ) }
);

export type PutStudentMutationVariables = Exact<{
  input: StudentInput;
}>;


export type PutStudentMutation = (
  { __typename?: 'Mutation' }
  & { putStudent: (
    { __typename?: 'Student' }
    & StudentFieldsFragment
  ) }
);

export type PutTimeSlotMutationVariables = Exact<{
  input: TimeSlotInput;
}>;


export type PutTimeSlotMutation = (
  { __typename?: 'Mutation' }
  & { putTimeSlot: (
    { __typename?: 'TimeSlot' }
    & TimeSlotFieldsFragment
  ) }
);

export type BatchUpdateAssignmentsMutationVariables = Exact<{
  toAdd: Array<AssignmentInput>;
  toDelete: Array<Scalars['ID']>;
  shouldAdd: Scalars['Boolean'];
  shouldDelete: Scalars['Boolean'];
}>;


export type BatchUpdateAssignmentsMutation = (
  { __typename?: 'Mutation' }
  & { batchPutAssignments: Array<(
    { __typename?: 'Assignment' }
    & AssignmentFieldsFragment
  )>, batchDeleteAssignments: Array<(
    { __typename?: 'Assignment' }
    & Pick<Assignment, 'id'>
  )> }
);

export type SchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type SchedulesQuery = (
  { __typename?: 'Query' }
  & { schedules: Array<(
    { __typename?: 'Schedule' }
    & ScheduleFieldsFragment
  )> }
);

export type ScheduleFieldsFragment = (
  { __typename?: 'Schedule' }
  & Pick<Schedule, 'id' | 'isActive' | 'createdAt' | 'name'>
);

export type TimeSlotFieldsFragment = (
  { __typename?: 'TimeSlot' }
  & Pick<TimeSlot, 'id' | 'isActive' | 'createdAt' | 'scheduleId' | 'name' | 'description' | 'start' | 'end' | 'duration' | 'recurrenceType' | 'recurrenceRate' | 'recurrenceDays'>
  & { assignments: Array<(
    { __typename?: 'Assignment' }
    & AssignmentFieldsFragment
  )> }
);

export type EventFieldsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'createdAt' | 'scheduleId' | 'timeSlotId' | 'dateTime' | 'content'>
);

export type StudentFieldsFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id' | 'isActive' | 'createdAt' | 'name' | 'scheduleId'>
);

export type AssignmentFieldsFragment = (
  { __typename?: 'Assignment' }
  & Pick<Assignment, 'id' | 'createdAt' | 'scheduleId' | 'timeSlotId' | 'studentId'>
);

export const ScheduleFieldsFragmentDoc = gql`
    fragment ScheduleFields on Schedule {
  id
  isActive
  createdAt
  name
}
    `;
export const AssignmentFieldsFragmentDoc = gql`
    fragment AssignmentFields on Assignment {
  id
  createdAt
  scheduleId
  timeSlotId
  studentId
}
    `;
export const TimeSlotFieldsFragmentDoc = gql`
    fragment TimeSlotFields on TimeSlot {
  id
  isActive
  createdAt
  scheduleId
  name
  description
  start
  end
  duration
  recurrenceType
  recurrenceRate
  recurrenceDays
  assignments {
    ...AssignmentFields
  }
}
    ${AssignmentFieldsFragmentDoc}`;
export const EventFieldsFragmentDoc = gql`
    fragment EventFields on Event {
  id
  createdAt
  scheduleId
  timeSlotId
  dateTime
  content
}
    `;
export const StudentFieldsFragmentDoc = gql`
    fragment StudentFields on Student {
  id
  isActive
  createdAt
  name
  scheduleId
}
    `;
export const GetScheduleDocument = gql`
    query GetSchedule($id: ID!, $start: AWSDateTime!, $end: AWSDateTime!) {
  getSchedule(id: $id) {
    ...ScheduleFields
  }
  timeSlots(scheduleId: $id, start: $start, end: $end) {
    ...TimeSlotFields
  }
  events(scheduleId: $id, start: $start, end: $end) {
    ...EventFields
  }
  students(scheduleId: $id) {
    ...StudentFields
  }
}
    ${ScheduleFieldsFragmentDoc}
${TimeSlotFieldsFragmentDoc}
${EventFieldsFragmentDoc}
${StudentFieldsFragmentDoc}`;

export function useGetScheduleQuery(options: Omit<Urql.UseQueryArgs<GetScheduleQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetScheduleQuery>({ query: GetScheduleDocument, ...options });
};
export const PutEventDocument = gql`
    mutation PutEvent($input: EventInput!) {
  putEvent(input: $input) {
    ...EventFields
  }
}
    ${EventFieldsFragmentDoc}`;

export function usePutEventMutation() {
  return Urql.useMutation<PutEventMutation, PutEventMutationVariables>(PutEventDocument);
};
export const PutScheduleDocument = gql`
    mutation PutSchedule($input: ScheduleInput!) {
  putSchedule(input: $input) {
    ...ScheduleFields
  }
}
    ${ScheduleFieldsFragmentDoc}`;

export function usePutScheduleMutation() {
  return Urql.useMutation<PutScheduleMutation, PutScheduleMutationVariables>(PutScheduleDocument);
};
export const PutStudentDocument = gql`
    mutation PutStudent($input: StudentInput!) {
  putStudent(input: $input) {
    ...StudentFields
  }
}
    ${StudentFieldsFragmentDoc}`;

export function usePutStudentMutation() {
  return Urql.useMutation<PutStudentMutation, PutStudentMutationVariables>(PutStudentDocument);
};
export const PutTimeSlotDocument = gql`
    mutation PutTimeSlot($input: TimeSlotInput!) {
  putTimeSlot(input: $input) {
    ...TimeSlotFields
  }
}
    ${TimeSlotFieldsFragmentDoc}`;

export function usePutTimeSlotMutation() {
  return Urql.useMutation<PutTimeSlotMutation, PutTimeSlotMutationVariables>(PutTimeSlotDocument);
};
export const BatchUpdateAssignmentsDocument = gql`
    mutation BatchUpdateAssignments($toAdd: [AssignmentInput!]!, $toDelete: [ID!]!, $shouldAdd: Boolean!, $shouldDelete: Boolean!) {
  batchPutAssignments(input: $toAdd) @include(if: $shouldAdd) {
    ...AssignmentFields
  }
  batchDeleteAssignments(input: $toDelete) @include(if: $shouldDelete) {
    id
  }
}
    ${AssignmentFieldsFragmentDoc}`;

export function useBatchUpdateAssignmentsMutation() {
  return Urql.useMutation<BatchUpdateAssignmentsMutation, BatchUpdateAssignmentsMutationVariables>(BatchUpdateAssignmentsDocument);
};
export const SchedulesDocument = gql`
    query Schedules {
  schedules {
    ...ScheduleFields
  }
}
    ${ScheduleFieldsFragmentDoc}`;

export function useSchedulesQuery(options: Omit<Urql.UseQueryArgs<SchedulesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SchedulesQuery>({ query: SchedulesDocument, ...options });
};