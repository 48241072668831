import React from 'react'

export function useStateWithDeferredSetter<TState>(
  initialState: TState | (() => TState)
) {
  const [state, setState] = React.useState(initialState)
  const deferredSetter = React.useCallback(
    (state: TState) => () => {
      setState(state)
    },
    []
  )

  return [state, deferredSetter] as const
}
